import {createAction, props} from '@ngrx/store';
import {IAssetConfiguration, IRootAssetTypes} from './interfaces/IAsset.types.modal';
import {AssetImageConfigHolder, Assets, AssetsCounts, AssetsLinked, GroupHolder, IAssetCardImgStatus} from './interfaces/Assets.modal';
import {ITimeSlots} from './interfaces/ITimeSlots.modal';
import {FilterMetadata, Message} from 'primeng/api';
import {IAllAssignedCarriersData} from './interfaces/IAllAssignedCarriers.model';
import { IAllDeliveryData, IGeoFenceData} from './interfaces/IAllDeliveries.model';
import {ICategorySequencePayload} from './interfaces/IAsset.category.model';
import {IRoutePlan} from './interfaces/I-RoutePlan.table.header';
import {O2DRequestOptions} from "../../state/Assets/assets.service";
import {IOrderAsset, IOrderAssets} from "../../state/Assets/interfaces/IAsset.order.modal";
import { IDeliveredParams } from '../Settings/interfaces/lmdMmdSettings.interface';


export const setLoaded = createAction(
  '[Assets Store] set if the asset data loaded',
  props<{ data: string }>()
);
export const setOrderTableFilterMeta = createAction(
  '[Assets Store] set Order Table FilterMeta',
  props<{ id: string, data: FilterMetadata }>()
);
export const setSelectedAssetType = createAction(
  '[Assets Store] set the selected asset type in route',
  props<{ assetType: string }>()
);
export const loadAllAssetsCount = createAction(
  '[Assets Effect] load all assets count',
);
export const loadAssetsConfigSuccess = createAction(
  '[Assets Store] load all assets config success',
  props<{ data: IRootAssetTypes[] }>()
);
export const loadAssetCountByType = createAction(
  '[Assets Effects] load asset count by type',
  props<{ assetType: string }>()
);
export const setAssetCountInConfig = createAction(
  '[Assets Store] set asset count in config by type',
  props<{ assetType: string, count: number }>()
);
export const loadAssetsByType = createAction(
  '[Assets Store] load all assets by type',
  props<{ data: IRootAssetTypes[] }>()
);
export const loadEachAssetByType = createAction(
  '[Assets Effort] load each asset by type',
  props<{ assetType: string, limit: number, configuration?: IAssetConfiguration, archived: boolean }>()
);
export const loadAssetsFromStateInitially = createAction(
  '[Assets Store] load asset by type from state initially',
  props<{skip:number,limit:number}>()
);
export const loadAssetsFromState = createAction(
  '[Assets Store] load asset by type from state',
  props<{ assetType: string, skip: number, limit: number }>()
);

export const setCurrentPageSkipLimit = createAction(
  '[Assets Store] set current asset page number',
  props<{assetType:string,skip: number, limit: number}>()
)
export const loadAssetsFromEffects = createAction(
  '[Assets Effect] load asset by type from effects',
  props<{ assetType: string, skip: number, limit: number }>()
);
export const loadAssetsFromEffectsSuccess = createAction(
  '[Assets Store] load asset by type from effects Success',
  props<{ assetType: string, data }>()
);
export const loadAssetByTypeSuccess = createAction(
  '[Assets Store] load asset by type Success',
  props<{ assetType: string, data: Assets }>()
);
export const loadOrderTableHeader = createAction(
  '[Assets Store] load Order Table Header by type',
  props<{ id: string }>()
);
export const loadOrderTableHeaderData = createAction(
  '[Assets Store] load Order effect Table Header Data by type',
  props<{ id: string }>()
);
export const loadOrderTableHeaderDataSuccess = createAction(
  '[Assets Store] load Order Table Header Data by type success',
  props<{ id: string, fields: unknown ,isCustomerCollabration:boolean }>()
);
export const loadAssetCountByTypeSuccess = createAction(
  '[Assets Store] load asset count by type Success',
  props<{ data: AssetsCounts }>()
);
export const failure = createAction(
  '[Asset Store] failed',
  props<{ error: Error }>()
);
export const updateOrderTableHeaderDataIsColumSeen = createAction(
  '[Assets Store] update Order Table Header Data IsColumSeen',
  props<{ id: string, key: string, data: boolean }>()
);
export const updateOrderTableHeaderDataPickUpLocation = createAction(
  '[Assets Store] update Order Table Header Data PickUp Location',
  props<{ id: string, data: FilterMetadata }>()
);
export const loadOrderTimeSlotsSuccess = createAction(
  '[Assets Store] load order time slots success',
  props<{ data: ITimeSlots[] }>()
);
export const loadConsignmentTableHeaderData = createAction(
  '[Assets Store] load Consignment Table Header Data by type',
  props<{ id: string }>()
);
export const loadExpansionConsignmentTableHeaderData = createAction(
  '[Assets Store] load Expansion Consignment Table Header Data',
  props<{ id: string }>()
);
export const loadVehicleTableHeaderData = createAction(
  '[Assets Store] load vehicle Table Header Data by type',
  props<{ id: string }>()
);
export const loadLogisticProviderLinkedCities = createAction(
  '[Assets Store] load logisticProvider linked cities',
  props<{ data: AssetsLinked[], moduleUniqueId?: string, id: string }>()
);
export const getLogisticProviderLinkedCities = createAction(
  '[Assets Store] get logisticProvider linked cities',
  props<{
    lpId: string,
    cityAssetType: string,
    moduleUniqueId: string,
    id: string,
    isCount?: boolean,
    skip?: number,
    limit?: number
  }>()
);
export const loadLmdOrderTableHeaderData = createAction(
  '[Assets Store] load LMD order Table Header Data by type',
  props<{ id: string }>()
);
export const loadAgentTableHeaderData = createAction(
  '[Assets Store] load Agent Table Header Data by type',
  props<{ id: string }>()
);
export const updateDateRangeFieldDefaultFilterValue = createAction(
  '[Assets Store] update date range field default filter value',
  props<{ id: string, key: string }>()
);


export const loadAllAssignedCarriersData = createAction(
  '[Assets Store] load All Assigned Carriers Data',
  props<{
    moduleName: string,
    status: string,
    isCount?: boolean,
    skip?: number,
    limit?: number,
    startDate?,
    endDate?,
    fInclude?: string,
    fExclude?: string,
    carrierIds?: string,
    orderPickUpCityId?: string,
    orderPickUpStoreId?: string
  }>()
)

export const loadAllAssignedCarriersDataSuccess = createAction(
  '[Assets Store] load All Assigned Carriers Data Success',
  props<{ data: IAllAssignedCarriersData[] }>()
)

export const loadAllAssignedCarriersCount = createAction(
  '[Assets Store] load All Assigned Carriers Count',
  props<{
    moduleName: string,
    status: string,
    isCount?: boolean,
    skip?: number,
    limit?: number,
    startDate?,
    endDate?,
    fInclude?: string,
    fExclude?: string,
    carrierIds?: string,
    orderPickUpCityId?: string,
    orderPickUpStoreId?: string
  }>()
)

export const loadAllAssignedCarriersCountSuccess = createAction(
  '[Assets Store] load All Assigned Carriers Count Success',
  props<{ data: AssetsCounts }>()
)

export const getAllDeliveries = createAction(
  '[Assets Store] get all deliveries',
  props<{
    moduleName: string,
    status: string,
    isCount?: boolean,
    skip?: number,
    limit?: number,
    startDate?,
    endDate?,
    fInclude?: string,
    fExclude?: string,
    carrierIds?: string,
    orderPickUpCityId?: string,
    orderPickUpStoreId?: string,
    isLinked?: boolean
  }>()
)

export const getAllDeliveriesCount = createAction(
  '[Assets Store] get all deliveries count',
  props<{
    moduleName: string,
    status: string,
    isCount?: boolean,
    skip?: number,
    limit?: number,
    startDate?,
    endDate?,
    fInclude?: string,
    fExclude?: string,
    carrierIds?: string,
    orderPickUpCityId?: string,
    orderPickUpStoreId?: string,
    isLinked?: boolean,
    searchText?: string,
    orderRoutePlanIds?: string,
    sortOrder?: string
  }>()
)

export const getAllDeliveriesCountSuccess = createAction(
  '[Assets Store] get all deliveries count success',
  props<{ data: AssetsCounts }>()
)

export const resetAllDeliveriesCount = createAction(
  '[Assets Store] reset all deliveries count'
)

export const getAllDeliveriesData = createAction(
  '[Assets Store] get all deliveries data',
  props<{ params : IDeliveredParams}>()
)

export const updateDeliveryData = createAction(
  '[Assets Store] update delivery data',
  props<{ data : IAllDeliveryData}>()
)

export const getAllDeliveriesDataSuccess = createAction(
  '[Assets Store] get all deliveries data success',
  props<{ data: IAllDeliveryData[] }>()
)
export const loadAssetDefaultImage = createAction(
  '[Assets Effects] load assets default image',
  props<{ assetType: string }>()
)
export const loadAssetDefaultImageSuccess = createAction(
  '[Assets store] load assets default image sucess',
  props<{ assetType: string, data: AssetImageConfigHolder }>()
)
export const reorderCatagery = createAction(
  "[assets store] reorder all the category list ",
  props<{ from: number, to: number, msg: Message }>()
)
export const putCategorySequence = createAction(
  "[assets store] upate reorder category sequence",
  props<{ payload: ICategorySequencePayload }>()
)
export const loadAssetLinkedGroupsCount = createAction(
  "[assets effect] load asset linked groups count",
  props<{ assetType: string }>()
)
export const loadAssetLinkedGroups = createAction(
  "[assets effect] load asset linked groups",
  props<{ skip: number, limit: number, assetType: string }>()
)
export const loadAssetLinkedGroupsSuccess = createAction(
  "[assets store] load asset linked groups success",
  props<{ data: GroupHolder }>()
)
export const loadState = createAction(
  "[assets store] load state"
)

export const getKioskTableHeaderData = createAction(
  "[assets store] get kiosk table header data",
  props<{ id: string }>()
)
export const getKioskTableHeaderDataSuccess = createAction(
  "[assets store] get kiosk table header data Success",
  props<{ id: string , fields: unknown }>()
)
export const checkMobileView = createAction(
  '[Assets store] check if it is mobile view'
)

export const getAssetsLinkedById = createAction(
  '[Assets store] get assets linked by assetId',
  props<{ id: string }>()
)


export const getAssetsLinkedByIdSuccess = createAction(
  '[Assets store] get assets linked by assetId success',
  props<{ assetsList: unknown, id: string }>()
)

export const removeCachedAssetLinkData = createAction(
  '[Assets store], delete record of updated asset link',
  props<{ ids: string[] }>()
)

export const loadRoutePlanTableHeaderData = createAction(
  '[Assets Store] load MMD Table Header Data by type success',
  props<{ id: string }>()
);

export const loadScheduleTableHeaderData = createAction(
  '[Assets Store] load MMD Schedule Table Header Data by type success',
  props<{ id: string }>()
);

export const loadMmdManagementTableHeaderData = createAction(
  '[Assets Store] load mmd management Table Header Data',
  props<{ id: string }>()
);

export const loadLmdManagementTableHeaderData = createAction(
  '[Assets Store] load lmd management Table Header Data',
  props<{ id: string }>()
);

export const loadRoutePlanCount = createAction(
  '[Assets Effects] load route plans count',
  props<{
    moduleName: string,
    isCount: boolean,
    skip?: number,
    limit?: number,
    fI?: string,
    pickUpCityIds?: string,
    pickUpLocation?: string,
    search?: string
  }>()
);

export const loadRoutePlans = createAction(
  '[Assets Effects] load route plans',
  props<{
    moduleName: string,
    isCount: boolean,
    skip?: number,
    limit?: number,
    fI?: string,
    pickUpCityIds?: string,
    pickUpLocation?: string,
    search?: string
  }>()
);

export const loadRoutePlansCountSuccess = createAction(
  '[Assets Store] load route plan count success',
  props<{ count: number }>()
);

export const loadRoutePlansSuccess = createAction(
  '[Assets Store] load route plans success',
  props<{ data: IRoutePlan[] }>()
);

export const resetParticularAssetCount = createAction(
  '[Assets Store] reset asset count',
  props<{ asset: string }>()
);

export const getGeoFenceByAssetId = createAction(
  '[Assets Store] geofence by asset id',
  props<{ assetId: string }>()
);

export const geoFenceByAssetIdSuccess = createAction(
  '[Assets Store] geofence by asset id succcess',
  props<{ geofences }>()
);

export const updateGeoFenceRaduis = createAction(
  '[Assets Store] update geo fence radius',
  props<{ assetId: string, payload: IGeoFenceData }>()
);

export const geoFenceRaduisSuccess = createAction(
  '[Assets Store] update geo fence radius succcess'
);

export const loadO2DOrders = createAction(
  '[Assets Store] load O2D orders',
  props<{ options: O2DRequestOptions, key: string }>()
)

export const updateO2DOrders = createAction(
  '[Assets Store] update O2D orders',
  props<{ order: IOrderAsset, key: string }>()
)

export const loadO2DOrdersSuccess = createAction(
  '[Assets Store] load O2D orders Success',
  props<{ data: IOrderAssets , key: string }>()
)

export const getAssetCardsImageDisplayStatus = createAction(
  '[Assets Store] get asset cards image display status',
)

export const getAssetCardsImageDisplayStatusSuccess = createAction(
  '[Assets Store] get asset cards image display status success',
  props<{ data: Array<IAssetCardImgStatus>}>()
)
export const isActionButtonNeeded = createAction(
  '[Assets] check for casl in route plan',
  props<{value:boolean}>()
)

export const loadCommunicationHeaderData = createAction(
  '[Assets Store] load Communication Table Header Data by type success',
  props<{ id: string }>()
);

export const loadWearhouseTableHeaderData = createAction(
  '[Assets Store] load warehouse Table Header Data',
  props<{ id: string }>()
);


export const createMultipleAssets = createAction(
  '[Assets Store] create multiple assets',
  props<{ assetType: string, assetData: any[] }>()
);

export const createMultipleAssetsActionStatus = createAction(
  '[Assets Store] create multiple assets action status',
  props<{ status: number }>()
);

export const getSchedulesData = createAction(
  '[Assets Store] get All Schedules',
  props<{ skip?: number, limit?: number, isActive?: string, queries?:Record<string,string> ,key:string,startDate?,endDate?}>()
);

export const getSchedulesDataSuccess = createAction(
  '[Assets Store] get All Schedules Success',
  props<{ schedules: any, key: string }>()
);